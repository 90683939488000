import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { RouteSuspense } from './routes/RouteSuspense';
const PrivateRoutes = React.lazy(() => import('./routes/PrivateRoutes'));
const PublicRoutes = React.lazy(() => import('./routes/PublicRoutes'));

function App() {
  return (
    <Routes>
      <Route
        path="/*"
        element={
          <RouteSuspense>
            <PrivateRoutes />
          </RouteSuspense>
        }
      />
      <Route
        path="/external/*"
        element={
          <RouteSuspense>
            <PublicRoutes />
          </RouteSuspense>
        }
      />
    </Routes>
  );
}

export default App;
